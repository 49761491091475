<template>
  <div class="classroom">
    <div class="classroom-top">
      <div class="tea-search">
        <el-form ref="form" :inline="true" class="demo-form-inline">
          <!-- 班级状态 -->
          <el-form-item label="班级状态:" v-show="tab_pane_index=='second'||tab_pane_index=='third'">
            <el-select v-model="search_data.yxz" placeholder="请选择" @change="handelSearch">
              <el-option :value="null" label="全部" :key="null"></el-option>
              <el-option :value="1" label="有效" :key="1"></el-option>
              <el-option :value="0" label="无效" :key="0"> </el-option>
              <el-option :value="2" label="未生效" :key="2"> </el-option>
            </el-select>
          </el-form-item>
          <!-- 搜索框 -->
          <el-form-item>
            <div class="search-text" style="width:300px">
              <el-input placeholder="请输入班级名称或关键字查询" v-model="search_data.bjmc" class="input-with-select">
              </el-input>
            </div>
          </el-form-item>
          <!-- 查询按钮 -->
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" class="fl tea-btn" @click="handelSearch">查 询</el-button>
          </el-form-item>
          <!-- 下载集训申请表 -->
          <el-form-item v-show="tab_pane_index!='first'">
            <el-button icon="el-icon-download" type="primary" class="fl tea-btn" @click="downloadApplication()">下载集训申请表</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table">
      <!-- tab切换 -->
      <el-tabs type="border-card" v-model="tab_pane_index" @tab-click='tabClick()'>
        <el-tab-pane label="常规班级" name="first" v-if="is_pinke==0"></el-tab-pane>
        <el-tab-pane label="临时班级（已安排集训）" name="second"></el-tab-pane>
        <el-tab-pane label="临时班级（未安排集训）" name="third"></el-tab-pane>
      </el-tabs>
      <!-- tableData -->
      <el-table stripe border :data="tableData" height="calc(100% - 90px)">
        <el-table-column label="序号" type="index" align="center" :index="indexMethod" width="80" />
        <el-table-column prop="bjmc" label="班级名称" align="center" />
        <el-table-column prop="bjbm" label="班级别名" align="center" v-if="tab_pane_index == 'first'" />
        <el-table-column prop="yxsj" label="有效时间" align="center" v-if="tab_pane_index == 'second'" width="230">
          <template v-slot="scope">
            <span>{{new Date(scope.row.yxqq).Format("yyyy-MM-dd")}}</span>
            至
            <span>{{new Date(scope.row.yxqz).Format("yyyy-MM-dd")}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="yxsj" label="有效时间" align="center" v-if="tab_pane_index == 'third'">
          <template v-slot="scope">
            <span>{{new Date(scope.row.yxqq).Format("yyyy-MM-dd")}}</span>
            至
            <span>{{new Date(scope.row.yxqz).Format("yyyy-MM-dd")}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="personCount" label="班级人数" align="center" width="120" v-if="tab_pane_index">
          <template #header>
            <div class="clearfix">
              <span class="header-title">班级人数</span>
              <el-tooltip class="item" effect="dark" content="班级人数 = 学生人数+教师人数" placement="top-end">
                <i class="el-icon-info" style="color:#409EFF;margin-left:10px"></i>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <!-- --常规班显示-- -->
        <el-table-column prop="bzrxm" label="班主任" align="center" v-if="tab_pane_index == 'first'" />
        <el-table-column prop="bzrsjh" label="班主任电话" align="center" v-if="tab_pane_index == 'first'" />
        <!-- 已安排集训的临时班显示 -->
        <el-table-column prop="jxmc" label="关联集训" align="center" v-if="tab_pane_index == 'second'" />
        <el-table-column prop="jxStatus" label="集训状态" align="center" v-if="tab_pane_index == 'second'" width="100">
          <template v-slot="scope">
            <el-tag :type="scope.row.jxStatus=='进行中'?'success':scope.row.jxStatus=='已结束'?'warning':''">{{scope.row.jxStatus}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="jxYxqq" label="集训有效时间" align="center" v-if="tab_pane_index == 'second'" width="230">
          <template v-slot="scope">
            <span>{{new Date(scope.row.jxYxqq).Format("yyyy-MM-dd")}}</span>
            至
            <span>{{new Date(scope.row.jxYxqz).Format("yyyy-MM-dd")}}</span>
          </template>
        </el-table-column>
        <!-- --操作- -->
        <el-table-column label="操作" align="center" width="120px">
          <template v-slot="scope">
            <el-button type="primary" size="mini" plain @click="toStu(scope.row)" v-if="tab_pane_index == 'first'">进入班级</el-button>
            <el-button type="primary" size="mini" plain @click="toStu(scope.row)" v-else :disabled="(!scope.row.bjStatus)||(tab_pane_index == 'second'&&(scope.row.jxStatus=='未开始'||scope.row.jxStatus=='已结束'))">进入班级</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="tea-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pages.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pages.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pages.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { imgUrl } from '@/utils/index'
import { getClassListApi, getTempClassListApi } from '@/api/teacher'
export default {
  name: 'classroom',
  components: {
  },
  data() {
    return {
      is_pinke: 0,
      yhid: this.$store.state.userInfo.yhid,
      zhid: this.$store.state.userInfo.zhid,
      tab_pane_index: 'second',
      search_data: {
        yhid: this.$store.state.userInfo.yhid,
        cjr: this.$store.state.userInfo.yhid,
        bjmc: null,
        yxz: 1,
        zhid: this.$store.state.userInfo.zhid,
      },
      pages: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      class_list: [],
      imgUrl: imgUrl,
      tableData: [],
      pattern: '' // 关键字查询班级
    }
  },
  watch: {
  },
  created() {
    this.$emit('change-top', [
      { title: '首页', pathName: '' },
      { title: '我的班级', pathName: '' }
    ])
    if (this.$store.state.userInfo.zhmc == "上海品课学院") {
      this.is_pinke = 1;
      // this.tab_pane_index = 'second'
    } else {
      this.is_pinke = 0;
      // this.tab_pane_index = 'first'
    }
    this.getClassList()
  },
  computed: {
  },
  mounted() {

  },
  methods: {
    // 切换常规班级临时班级
    tabClick() {
      this.tableData = []
      this.search_data.bjmc = null
      this.handelSearch()
    },
    // 查询班级
    getClassList() {
      const data = this.search_data;
      data.currentPage = this.pages.currentPage;
      data.pageSize = this.pages.pageSize;
      let api = getClassListApi;
      if (this.tab_pane_index == 'second') {
        // 有集训
        api = getTempClassListApi;
        data.ywjx = 1
      } else if (this.tab_pane_index == 'third') {
        // 无集训
        api = getTempClassListApi;
        data.ywjx = 0
      }
      data.yycdid = this.$store.state.yycdid
      api(data).then(res => {
        if (res.result) {
          this.pages.total = res.result.total
          this.tableData = res.result.list;
        }
      })
    },
    indexMethod(index) {
      return (this.pages.currentPage - 1) * this.pages.pageSize + index + 1;
    },
    // ------------------------------------------------------分页器
    handleSizeChange(val) {
      this.pages.currentPage = 1;
      this.pages.pageSize = val;
      this.getClassList()
    },
    //  -----------------------------------------------------分页器
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getClassList()
    },
    // 点击查询
    handelSearch() {
      // 点击查询，页数，每页显示个数，初始化
      this.pages.currentPage = 1;
      this.pages.pageSize = 10
      this.getClassList()
    },
    // 进入学生管理
    toStu(data) {
      let lx;
      let ywjx
      if (this.tab_pane_index == 'first') {
        lx = 1
      } else if (this.tab_pane_index == 'second') {
        lx = 2;
        ywjx = 1
      } else if (this.tab_pane_index == 'third') {
        lx = 2;
        ywjx = 0
      }
      this.$router.push({ path: 'classStudent', query: { classId: data.bjid, bjmc: data.bjmc, class_type: data.zt, pmzt: data.pmzt, lx, ywjx } })
    },
    // 下载集训申请表
    downloadApplication() {
      window.open('https://pk-words.oss-cn-shanghai.aliyuncs.com/guide/%E9%9B%86%E8%AE%AD%E7%94%B3%E8%AF%B7%E8%A1%A8.xls')
    },
  }
}
</script>
<style lang="scss" scoped>
.classroom {
  height: 100%;
  .table {
    height: calc(100% - 100px);
    .tea-pagination {
      margin-top: 15px;
    }
    .el-tabs--border-card {
      border: 1px solid #ebeef5;
      box-shadow: none;
      border-bottom: none;
      :deep(.el-tabs__header) {
        border-bottom: none !important;
      }
    }
  }
}
.classroom-top {
  padding-top: 10px;
}
</style>
<style lang="scss">
.classroom {
  // .el-table__body-wrapper {
  //   height: 100% !important;
  // }
}
</style>



